import { useEffect, useState } from "react";
import { ethers } from "ethers";

export const useMetaMaskLogIn = () => {
  const [isLogInMetaMask, setLogInMetaMask] = useState(false);

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      isMetaMaskConnected(provider).then((res) => setLogInMetaMask(res));
    }
  }, [ethers]);

  const isMetaMaskConnected = async (provider) => {
    const accounts = await provider.listAccounts();
    return accounts.length > 0;
  };

  return [isLogInMetaMask];
};
