import styled from "styled-components";

export const ContentWrapper = styled.div`
  position: relative;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  // width: 2000px;
  // height: 2000px;
  background-image: url(${process.env.PUBLIC_URL + "/images/background.png"});
`;

export const LoaderContainer = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/loader.gif"});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 869px;
`;

export const ImageBlock = styled.image`
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  user-select: none;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
`;

export const modalWrapper = styled.div`
  max-width: 500px;
  min-width: 100%;
`;
