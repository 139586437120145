const config = {
  contract: "0x8C52F8197AD8a1c44E08B71e58Ba59D68a8D2135",
  abi: [
    {
      inputs: [
        {
          internalType: "uint256",
          name: "n",
          type: "uint256",
        },
        {
          internalType: "uint256[]",
          name: "prices",
          type: "uint256[]",
        },
      ],
      name: "init",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "payoff",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "i",
          type: "uint256",
        },
        {
          internalType: "string",
          name: "code",
          type: "string",
        },
        {
          internalType: "string",
          name: "caption",
          type: "string",
        },
        {
          internalType: "string",
          name: "url",
          type: "string",
        },
      ],
      name: "save",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      stateMutability: "payable",
      type: "constructor",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "i",
          type: "uint256",
        },
      ],
      name: "get",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
        {
          internalType: "string",
          name: "",
          type: "string",
        },
        {
          internalType: "address",
          name: "",
          type: "address",
        },
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ],
};

export default config;
