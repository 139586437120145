import "./EUButton.css";

function EUButton({ content, outerClass, readOnly = false, onClick }) {
  function buttonClicked(e) {
    if (!readOnly) {
      onClick?.(e);
    }
  }

  return (
    <div
      className={
        (outerClass || "") +
        " eu-Button-container eu-centered " +
        (readOnly ? "eu-Button-disabled" : "eu-cursor-pointer")
      }
      onClick={buttonClicked}
    >
      <div className="eu-Button-content">{content}</div>
    </div>
  );
}

export default EUButton;
