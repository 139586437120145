import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import EUButton from "../EUButton/EUButton";
import "../Upload/Upload.css";
import imageCompression from "browser-image-compression";

export const customStyles = {
  content: {
    padding: "15px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    maxHeight: "80%",
    minWidth: "30%",
    minHeight: "25%",
    zIndex: "9999",
    position: "absolute",
    background:
      "linear-gradient(115.77deg, rgba(129, 169, 247, 0.8) 0%, #143F93 0.01%, #12B3AC 100%)",
  },
};

export const CropImageModal = ({
  upImg,
  imgRef,
  onLoad,
  isOpenCrop,
  closeCropModal,
  setFinishImage,
  chosenBlock,
  acceptedFiles,
  setCompletedCrop,
  completedCrop,
  previewCanvasRef,
}) => {
  const [crop, setCrop] = useState({
    width: chosenBlock?.width,
    height: chosenBlock?.height,
  });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const optionsToCompress = {
    maxSizeMB: 0.015,
    useWebWorker: true,
  };

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        const file = blobToFile(blob, "image/png");
        imageCompression(file, optionsToCompress)
          .then((file) => setFinishImage(file))
          .catch((e) => console.log(e));
      },
      "image/png",
      1
    );
    closeCropModal();
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "medium";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <Modal
      isOpen={isOpenCrop}
      style={customStyles}
      onRequestClose={closeCropModal}
    >
      <div className="crop_modal">
        <div>{"Select the part of the picture to fit the block size,"}</div>
        <div>{`or upload other picture matches the size ${chosenBlock.width}×${chosenBlock.height}`}</div>
        <div className="crop_content">
          <ReactCrop
            locked={true}
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{
              maxHeight: '400px',
              overflowY: 'scroll',
            }}
          />
        </div>
        <div className="crop_actions">
          <EUButton
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)
            }
            content="Save"
          />
          <EUButton
            onClick={() => {
              setFinishImage(null);
              closeCropModal();
              removeAll();
            }}
            content={"Close"}
          />
        </div>
      </div>
    </Modal>
  );
};
