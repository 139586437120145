import React from "react";
import ReactDOM from "react-dom";
import Popup from "react-popup";
import "./index.css";
import App from "./components/App/App";
import config from "react-global-configuration";
import configuration from "./config";
import reportWebVitals from "./reportWebVitals";

config.set(configuration);

// Рендерим приложение
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Рендерим alert-всплывашку
ReactDOM.render(<Popup />, document.getElementById("alertContainer"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
