import EUButton from "../EUButton/EUButton";

export default function NoMetamaskModal({ close }) {
  return (
    <>
      <h3>Warning</h3>
      <div>
        In order to see the data stored on Blockchain, you need to install and
        connect Metamask in your browser
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <EUButton {...{ content: "Ok", onClick: close }} />
      </div>
    </>
  );
}
