import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";
import "./Upload.css";
import { useDropzone } from "react-dropzone";
import { CropImageModal } from "../CropImageModal/CropImageModal";
import { calculatePriseOfSeat, getImageId } from "../../functions/helpers";
import { PreviewWrapper } from "../Header/Header.style";

function UploadImageModal(
  {
    activatePay,
    chosenBlock,
    setFinishImage,
    finishImage,
    myOwnImages,
    isOwnBlock,
    imageCaption,
    setCaption,
    imageURL,
    setURL,
  },
  ref
) {
  const [inputFocused, changeInputFocused] = useState(false);
  const [inputUrlFocused, changeUrlInputFocused] = useState(false);
  const [imageTitle, setTitle] = useState(chosenBlock?.image?.title || null);

  const [inputKey, setInputKey] = useState(Math.random().toString(36));
  const [uploadResultMessage, setResultMessage] = useState("");
  const [chosenParams, setChosenParams] = useState({});
  const [upImg, setUpImg] = useState(null);
  const [isOpenCrop, setOpenCrop] = useState(false);
  const imgRef = useRef(null);
  const fileLoaderRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);

  useEffect(() => {
    if (chosenBlock?.title) {
      setChosenParams({
        title: chosenBlock.title,
        linkurl: chosenBlock.getAttribute("linkurl"),
      });
    }
  }, [chosenBlock]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const closeCropModal = () => {
    setOpenCrop(false);
  };

  const returnLabelForCaptionBackDown = (event) => {
    if (event.target.value === "") {
      changeInputFocused(false);
    }
  };

  const returnLabelForUrlBackDown = (event) => {
    if (event.target.value === "") {
      changeUrlInputFocused(false);
    }
  };

  useImperativeHandle(ref, () => ({
    destroy: () => {
      changeInputFocused(false);
      changeUrlInputFocused(false);
      setInputKey(Math.random().toString(36));
      activatePay(false);
      setResultMessage("");
    },
    getCurrentImageConfig: () => ({
      title: imageTitle,
      caption: imageCaption,
      imageURL: imageURL,
    }),
    updateImageConfig: (updates) => {
      setTitle(updates?.title || "");
      setCaption(updates?.caption || "");
    },
  }));

  // Обработка выбранного файла
  function onDrop(event) {
    const MAX_FILE_SIZE_AVAILABLE = 18432;
    const _URL = window.URL || window.webkitURL;
    const file = event[0];
    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);

    const reader = new FileReader();
    reader.addEventListener("load", () => setUpImg(reader.result));
    reader.readAsDataURL(file);
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      if (
        canvas.width < chosenBlock?.width ||
        canvas.height < chosenBlock?.height
      ) {
        activatePay(false);
        setResultMessage(
          `This file is so small, please get file over ${chosenBlock?.width} width and ${chosenBlock?.height} height`
        );
      } else {
        activatePay(true);
        const ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0);
        setResultMessage(
          !isOwnBlock(myOwnImages) &&
            `Click 'Pay' button below to transfer: ${calculatePriseOfSeat(
              chosenBlock,
              1
            )}Eth.`
        );
        setOpenCrop(true);
      }
    };
    img.src = objectUrl;
    setTitle(file.name);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({ onDrop });

  return (
    <div className="ew-Upload eu-centered">
      {/*<div className="ew-Upload-header">Select the picture to upload</div>*/}
      <div style={{ cursor: "pointer" }} {...getRootProps()}>
        <input
          ref={fileLoaderRef}
          multiple={false}
          hidden={true}
          {...getInputProps()}
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag and drop the picture here, or click to select a file</p>
        )}
      </div>
      <PreviewWrapper isFileExist={finishImage}>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </PreviewWrapper>
      <CropImageModal
        previewCanvasRef={previewCanvasRef}
        setCompletedCrop={setCompletedCrop}
        completedCrop={completedCrop}
        fileLoaderRef={fileLoaderRef}
        acceptedFiles={acceptedFiles}
        chosenBlock={chosenBlock}
        setFinishImage={setFinishImage}
        upImg={upImg}
        imgRef={imgRef}
        onLoad={onLoad}
        isOpenCrop={isOpenCrop}
        closeCropModal={closeCropModal}
      />
      <div
        className={
          "ew-Upload__input " +
          (inputFocused || isOwnBlock(myOwnImages) ? "focused" : "")
        }
      >
        {" "}
        {/* to inputFocusedTitle*/}
        <label htmlFor="caption" className="ew-Upload__input-label">
          {" "}
          Title{" "}
        </label>
        <input
          type="text"
          id="caption"
          maxLength="30"
          defaultValue={isOwnBlock(myOwnImages) ? chosenParams?.title : ""}
          onFocus={() => changeInputFocused(true)}
          onBlur={returnLabelForCaptionBackDown}
          onChange={(e) => setCaption(e.target.value)}
          autoComplete="off"
          className="ew-Upload__input-field"
        />
      </div>

      <div
        className={
          "ew-Upload__input " +
          (inputUrlFocused || isOwnBlock(myOwnImages) ? "focused" : "")
        }
      >
        <label htmlFor="url" className="ew-Upload__input-label">
          {" "}
          URL{" "}
        </label>
        <input
          type="text"
          id="url"
          maxLength="50"
          defaultValue={isOwnBlock(myOwnImages) ? chosenParams?.linkurl : ""}
          // on
          onFocus={() => changeUrlInputFocused(true)}
          onBlur={returnLabelForUrlBackDown}
          onChange={(e) => setURL(e.target.value)}
          autoComplete="off"
          className="ew-Upload__input-field"
        />
      </div>

      {imageTitle && (
        <p className="ew-Upload-description">{uploadResultMessage}</p>
      )}
    </div>
  );
}

UploadImageModal = forwardRef(UploadImageModal);

export default UploadImageModal;
