import "./Modal.css";
import EUButton from "./../EUButton/EUButton";
import { useState } from "react";

// Если было передано сообщение, то покажет его и сам проконтролирует собственное закрытие
export default function Modal({
  active,
  close,
  children,
  additional,
  message,
}) {
  if (message) {
    active = true;
  }
  return (
    <div
      className={
        "ew-Modal eu-centered " +
        (active ? "active " : "") +
        (message ? "small-Modal" : "")
      }
    >
      <div className="ew-Modal-content eu-centered ">
        <div className="ew-Modal-children">{message || children}</div>
        <div
          className={
            "ew-Modal-buttons eu-centered " +
            (additional ? "space-between" : "")
          }
        >
          {additional && (
            <EUButton
              {...{
                content: additional.name,
                onClick: additional.action,
                readOnly: additional.readOnly,
              }}
            />
          )}
          <EUButton {...{ content: "Close", onClick: close }} />
        </div>
      </div>
    </div>
  );
}
