import config from "react-global-configuration";
import metamaskConfig from "../config";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {blobToBase64, getMyImages} from "./helpers";

export const useConnectMetamask = (pic_max) => {
  const { abi, contract } = metamaskConfig;
  const [alertMessage, setAlertMessage] = useState("");
  const [myStateAddress, setMyAddress] = useState(null);
  const [pricesImage, setPricesImage] = useState([]);
  const [ImagesOwners, setImagesOwners] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isOwnBlock, setIsOwnBlock] = useState(false);
  const [isConnect, setIsConnect] = useState(null);
  const [needConnect, setNeedConnect] = useState(true);
  const [bookingImagesLength, setBookingImages] = useState(0);
  const [isMetaMask, setIsMetaMask] = useState(false);
  const bookingImagesSet = new Set();

  const alertPopup = (message) => {
    setTimeout(() => setAlertMessage(message), 1000);
  };
  const myOwnImages = getMyImages(ImagesOwners, myStateAddress);

  const payPostRequestToMetamask = (i, pic_base64, caption, url, price) => {
    const newPrice = Number(price.toHexString()) * Math.pow(10,14);
    const newPriceToHex = newPrice.toString(16);
    const iFace = new ethers.utils.Interface(abi);
    const callData = iFace.encodeFunctionData("save", [
      i,
      pic_base64,
      caption,
      url,
    ]);
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [
          {
            from: myStateAddress,
            to: contract,
            value: isOwnBlock ? "0x00" : newPriceToHex,
            data: callData,
          },
        ],
      })
      .then((txHash) => console.log(txHash));
  };

  const updateBoard = (myAddress) => {
    // receiving all pics
    for (let i = 0; i < pic_max; i++) {
      getPic(i, myAddress);
    }
  };

  const getPic = (i, myAddress) => {
    const c = config.get("abi");
    const iface = new ethers.utils.Interface(config.get("abi"));
    const calldata = iface.encodeFunctionData("get", [i]);

    window.ethereum
      .request({
        method: "eth_call",
        params: [
          { from: myAddress, to: config.get("contract"), data: calldata },
        ],
      })
      .then((result) => {
        const text = iface.decodeFunctionResult("get", result);
        if (text[0] !== "") {
          const pic = document.getElementById(`pic${i.toString()}`);
          pic.setAttribute("src", text[0]);
          pic.setAttribute("title", text[2]);
          pic.setAttribute("linkUrl", text[4]);
          pic.style.cursor = 'pointer';
        }
        if (text[2] !== "") {
          bookingImagesSet.add(i);
          setBookingImages(bookingImagesSet.size);
        }
        if (text[3] !== 0) {
          ImagesOwners.push({ owner: text[3], id: i });
        }
        pricesImage.push({ price: text[1], id: i });
        if (i === 0) {
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const updateMyBoard = () => {
    isMetaMask === true &&
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          const myAddress = result.toString();
          setMyAddress(myAddress);
          updateBoard(myAddress);
          setIsConnect(true);
          setLoading(false);
          setNeedConnect(false);
        })
        .catch((e) => {
          setNeedConnect(true);
          console.log(e);
        });
  };

  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          const myAddress = result.toString();
          setMyAddress(myAddress);
          updateBoard(myAddress);
          setIsConnect(true);
          setNeedConnect(false);
        })
        .catch((err) => {
          if (err.code === 401) {
            alertPopup(
              "Reload the page and please allow application access to Metamask"
            );
          } else {
            console.error(err);
          }
        });
    } else {
      alertPopup("Please install Metamask");
    }
  };
  return [
    alertMessage,
    setAlertMessage,
    connectMetamask,
    updateMyBoard,
    payPostRequestToMetamask,
    pricesImage,
    myOwnImages,
    isLoading,
    isOwnBlock,
    setIsOwnBlock,
    needConnect,
    bookingImagesLength,
    isMetaMask,
    setIsMetaMask,
    isConnect
  ];
};
