import styled from "styled-components";
import { device } from "../../styles";

export const HeaderWrapper = styled.div`
  z-index: 999;
  position: fixed;
  height: 65px;
  background: linear-gradient(
    90.1deg,
    #36d4dc 0%,
    #81a9f7 50.46%,
    #4fd7cf 100%
  );
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  width: 100%;
  @media ${device.tabletMax} {
    justify-content: flex-start;
  }
`;

export const HeaderDescription = styled.div`
  min-width: 520px;
  max-height: 55px;
  display: flex;
  margin: 5px 0px 5px 10px;
  justify-content: space-between;
  font-style: italic;
  font-size: 12px;
  letter-spacing: 0.07em;
  align-items: center;
  @media ${device.laptopMmax} {
    display: flex;
    margin: 5px 5px 5px 10px;
    justify-content: center;
    min-width: 182px;
  }
  @media ${device.laptopMax} {
    display: none;
  }
`;

export const HeaderLogo = styled.img`
  width: 182px;
  height: 36px;
`;

export const HeaderLogoName = styled.div`
  max-width: 300px;
  margin: 8px 10px 10px 50px;
  @media ${device.laptopMmax} {
    display: none;
  }
`;

export const Logo = styled.span`
  font-style: italic;
  font-size: 12px;
  letter-spacing: 1px;
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
  max-width: 50%;
  min-width: 800px;
  @media ${device.laptopMmax} {
    min-width: 200px;
    max-width: 100%;
  }
  @media ${device.laptop} {
    min-width: 200px;
    max-width: 100%;
  }
  // @media ${device.tabletMax} {
  //   position: fixed;
  // }
`;

export const HeaderInfoBox = styled.div`
  width: 161px;
  height: 55px;
  background: #7185f1;
  display: flex;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding-left: 12px;
  min-width: 160px;
  @media (max-width: 660px) {
    display: none;
  }
`;

export const ActionControlWrapper = styled.div`
  display: flex;
  margin-left: 32px;
  margin-right: 34px;
  justify-content: space-between;
  padding-top: 5px;
  // @media ${device.laptopMax} {
  //   margin-right: 10px;
  // }
  @media ${device.tabletMax} {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const SizeBlock = styled.div`
  min-width: 75px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  @media (max-width: 460px){
    margin-left: 15px;
  };
  @media (max-width: 359px){
    font-size: 14px;
  };
  @media (max-width: 320px){
    margin-left: 5px;
  };
  @media (max-width: 290px){
    font-size: 13px;
    margin-left: 0;
  }
`;
export const PriceBlock = styled.div`
  min-width: 70px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  @media ${device.mobileLmax} {
    padding-left: 5px;
    min-width: 50px;
  };
  @media (max-width: 359px){
    font-size: 14px;
  };
  @media (max-width: 320px){
    min-width: 35px;
  };
  @media (max-width: 290px){
    font-size: 13px;
    min-width: 10px;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 50px;
  width: 162px;
  min-width: 162px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 10px;
  align-items: center;
  background: #7185f1;
  @media (max-width: 510px) {
    margin-left: 15px;
  };
  @media (max-width: 400px){
    width: 125px;
    min-width: 125px;
    font-size: 16px;
  };
  @media (max-width: 359px){
    width: 110px;
    min-width: 110px;
    font-size: 14px;
    margin-left: 10px;
  };
  @media (max-width: 290px){
    font-size: 13px;
    width: 100px;
    min-width: 100px;
    margin-left: 5px;
  }
`;

export const AboutWrapper = styled.div`
  min-width: 80px;
  margin-right: 20px;
  width: 100px;
  text-align: center;
  @media (max-width: 400px){
    font-size: 16px;
  };
  @media (max-width: 359px){
    font-size: 14px;
  };
  @media (max-width: 290px){
    font-size: 13px;
  }
`;

export const MenuButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
`;

export const DropMenuWrapper = styled.div`
  padding-top: 30px;
  @media ${device.laptopMax} {
    display: none;
  }
`;

export const AboutWrapperModal = styled.div`
  overflow: auto;
`;

export const UploadActions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const PreviewWrapper = styled.div`
  margin-top: 15px;
  display: ${(props) => (props.isFileExist ? "block" : "none")};
`;

export const ButtonImage = styled.img`
  width: 30px;
`;

export const ButtonContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.laptopMax} {  
  width: 110px;
  padding: 5px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin-top:10px;
  margin-bottom:10px;
  background: linear-gradient(115.77deg, rgba(129, 169, 247, 0.8) 0%, rgb(20, 63, 147) 0.01%, rgb(18, 179, 172) 100%);
  }
`;

export const ConnectButtonWrap = styled.div`
  color: white;
  background-color: inherit;
  @media ${device.laptopMax} {
    position: absolute;
    z-index: 999;
    top: 90px;
    right: 10px;
  }
`;
