const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1020px",
  laptopM: "1300px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
  mobileSmax: `(max-width: ${size.mobileS})`,
  mobileMmax: `(max-width: ${size.mobileM})`,
  mobileLmax: `(max-width: ${size.mobileL})`,
  tabletMax: `(max-width: ${size.tablet})`,
  laptopMax: `(max-width: ${size.laptop})`,
  laptopMmax: `(max-width: ${size.laptopM})`,
  laptopLmax: `(max-width: ${size.laptopL})`,
  desktopMax: `(max-width: ${size.desktop})`,
  desktopLmax: `(max-width: ${size.desktop})`,
};
