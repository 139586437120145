import { BigNumber } from "ethers";

export const PLACE_COUNT = 1414;

export const calculatePriseOfSeat = (image, coefficient) =>
  Math.round(image.height * image.width * 0.0001 * coefficient * 10000) / 10000;

export const calculatePriceOfSeatWithOutRound = (image) =>
  BigNumber.from(image.height * image.width * 0.0001);

export const blobToBase64 = (blob) => {
  if (blob.size){
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
} else {
    return new Promise(resolve => resolve(blob))
  }
};

export const getImageId = (chosenBlock) => {
  return Number(chosenBlock?.getAttribute("id").split("").splice(3).join(""));
};

export const findPriceByImageId = (priceArr, id) => {
  return priceArr.find((el) => el.id === id)?.price;
};

export const getMyImages = (ownerArr, myAddress) => {
  return ownerArr
    .filter((el) => el.owner.toLowerCase() === myAddress)
    .map((el) => el.id);
};
