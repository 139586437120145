import React, { useEffect, useRef, useState } from 'react'
import Modal from './../Modal/Modal'
import GridContent from './GridContent/GridContent'
import { getImageId, getMyImages } from '../../functions/helpers'
import Loader from 'react-loader-spinner'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const Content = ({
  setChosenBlock,
  chosenBlock,
  setHoverBlock,
  toggleBuyPopup,
  isBuying,
  alertMessage,
  setAlertMessage,
  updateMyBoard,
  myOwnImages,
  isLoading,
  openWithoutMetamask,
  isMetaMask,
  isLogInMetaMask,
  isConnect,
  firstCallUpdate,
  setFirstCallUpdate
}) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const updateTimeOut = setTimeout(() => {
      if(!firstCallUpdate){
        updateMyBoard()
        setFirstCallUpdate(true);
      }
    }, 2000)

    const updateInterval = setInterval(() => {
      if(isConnect){
        updateMyBoard()
      }
    }, 2000)

    return () => {
      clearTimeout(updateTimeOut)
      clearInterval(updateInterval)
    }
  }, [isConnect, firstCallUpdate, updateMyBoard]);

  useEffect(() => {
    if (!isBuying && chosenBlock) {
      chosenBlock?.classList?.remove('chosen')
      setChosenBlock(null)
    }
  }, [isBuying])

  const setHoverClassToImage = (e) => {
    e.target.classList.add('hovered')
    setHoverBlock(e.target)
  }
  const removeHoverClassToImage = (e) => {
    e.target.classList.remove('hovered')
    setHoverBlock(null)
  }
  const setClickClassToImageAndOpenBuyPopup = (e) => {
    if (
      e.target.getAttribute('title') &&
      !myOwnImages.includes(getImageId(e.target))
    ) {
      window.open(e.target.getAttribute('linkUrl'))
    } else {
      if (e.target !== chosenBlock && chosenBlock) {
        chosenBlock.classList.remove('chosen')
      }
      e.target.classList.add('chosen')
      setChosenBlock(e.target)
      toggleBuyPopup(true)
    }
  }
  const rootLoading = isLoading && !openWithoutMetamask && isLogInMetaMask

  return (
    <React.Fragment>
      <CSSTransition
        in={rootLoading}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="bg_layer" />
      </CSSTransition>
      {rootLoading && (
        <Loader
          type="TailSpin"
          color="yellow"
          height={150}
          width={150}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '50%',
            left: '50%',
            margin: '-75px 0 0 -75px',
            zIndex: '16',
          }}
        />
      )}

      <Modal message={alertMessage} close={() => setAlertMessage('')} />
      <GridContent
        removeHoverClassToImage={removeHoverClassToImage}
        setClickClassToImageAndOpenBuyPopup={
          setClickClassToImageAndOpenBuyPopup
        }
        setHoverClassToImage={setHoverClassToImage}
        wrapperRef={wrapperRef}
      />
    </React.Fragment>
  )
}

export default Content
