import { useState, useRef, useEffect } from "react";
import "./App.css";
import Header from "./../Header/Header";
import Content from "./../Content/Content";
import { useConnectMetamask } from "../../functions/useConnectMetamask";
import ReactModal from "react-modal";
import NoMetamaskModal from "../NoMetamaskModal/NoMetamaskModal";
import { useMetaMaskLogIn } from "../../functions/useMetaMaskLogIn";
import {PLACE_COUNT} from "../../functions/helpers";

function App() {
  const [sold, updateSold] = useState(0);
  const [available, updateAvailable] = useState(PLACE_COUNT);
  const [chosenBlock, setChosenBlock] = useState(null);
  const [hoveredBlock, setHoverBlock] = useState(null);
  const [isSticky, setSticky] = useState(false);
  const [isBuying, toggleBuyPopup] = useState(false);
  const [openWithoutMetamask, setOpenWithoutMetamask] = useState(false);
  const [firstCallUpdate, setFirstCallUpdate] = useState(false)
  const ref = useRef(null);
  const [
    alertMessage,
    setAlertMessage,
    connectMetamask,
    updateMyBoard,
    payPostRequestToMetamask,
    pricesImage,
    myOwnImages,
    isLoading,
    isOwnBlock,
    setIsOwnBlock,
    needConnect,
    bookingImagesLength,
    isMetaMask,
    setIsMetaMask,
    isConnect,
  ] = useConnectMetamask(available);

  const [isLogInMetaMask] = useMetaMaskLogIn();

  const customStyles = {
    content: {
      padding: "20px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "300px",
      maxHeight: "200px",
      minHeight: "20%",
      zIndex: "9999",
      position: "absolute",
      background:
        "linear-gradient(115.77deg, rgba(129, 169, 247, 0.8) 0%, #143F93 0.01%, #12B3AC 100%)",
      textAlign: "center",
      alignItems: "center",
      overflow: "hidden",
    },
  };

  useEffect(() => {
    updateSold(bookingImagesLength);
    updateAvailable(PLACE_COUNT - bookingImagesLength);
  }, [bookingImagesLength]);

  useEffect(() => setIsMetaMask(window.ethereum?.isMetaMask || false), []);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const openWallWithoutMetamask = () => setOpenWithoutMetamask(true);

  return isMetaMask || openWithoutMetamask ? (
    <div className="ew-App">
      <div
        className={`ew-App__sticky-wrapper${isSticky ? " sticky" : ""}`}
        ref={ref}
      >
        <Header
          myOwnImages={myOwnImages}
          pricesImage={pricesImage}
          payPostRequestToMetamask={payPostRequestToMetamask}
          connectMetamask={connectMetamask}
          isBuying={isBuying}
          toggleBuyPopup={toggleBuyPopup}
          sold={sold}
          available={available}
          chosenBlock={chosenBlock}
          hoveredBlock={hoveredBlock}
          updateChosen={(updates) =>
            setChosenBlock({
              ...(chosenBlock || {}),
              image: { ...chosenBlock?.image, ...(updates || {}) },
            })
          }
          _isOwnBlock={isOwnBlock}
          _setIsOwnBlock={setIsOwnBlock}
          needConnect={needConnect}
          isMetaMask={isMetaMask}
          isLoading={isLoading}
        />
      </div>
      <Content
        isLogInMetaMask={isLogInMetaMask}
        pricesImage={pricesImage}
        myOwnImages={myOwnImages}
        updateMyBoard={updateMyBoard}
        setAlertMessage={setAlertMessage}
        alertMessage={alertMessage}
        isBuying={isBuying}
        toggleBuyPopup={toggleBuyPopup}
        available={available}
        setChosenBlock={setChosenBlock}
        chosenBlock={chosenBlock}
        setHoverBlock={setHoverBlock}
        hoveredBlock={hoveredBlock}
        isLoading={isLoading}
        setIsOwnBlock={setIsOwnBlock}
        openWithoutMetamask={openWithoutMetamask}
        isMetaMask={isMetaMask}
        isConnect={isConnect}
        firstCallUpdate={firstCallUpdate}
        setFirstCallUpdate={setFirstCallUpdate}
      />
    </div>
  ) : (
    <ReactModal isOpen="true" style={customStyles}>
      <NoMetamaskModal close={() => openWallWithoutMetamask()} />
    </ReactModal>
  );
}

export default App;
