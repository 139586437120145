import EUButton from "../EUButton/EUButton";
import "../Modal/Modal.css";

function copyToClipboard(text) {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  alert(`${text} copied`);
}

export default function About({ close }) {
  return (
    <div
      className={"eu-centered active"}
      style={{
        flexDirection: "column",
        alignItems: "stretch",
        maxWidth: "800px",
      }}
    >
      <div className="ew-Modal-children">
        <div style={{ overflowX: "auto" }}>
          <h3 style={{ textAlign: "center" }}>About Ethereum Wall</h3>
          Welcome to the first advertising board on the Ethereum blockchain. All
          the pictures with your advert will be stored in the blockchain
          forever, except if you own the advertising space and wish to update
          it. If you are the owner, you can update the picture free anytime.{" "}
          <p /> In order to add the advertising picture to the blockchain, you
          need to install the Metamask extension to your web browser and have
          Ethereum coins on your account. Check the price for the block by
          clicking on it.
          <p /> I suggest you prepare the image by yourself to be sure you will
          receive exactly what you expected on the Ethereum wall, however our
          platform also provides you an image cutting function on the Buy page.
          Please keep in mind that blockchain has limitations on the data that
          can be uploaded, so we allow uploading images with a size less or
          equal to 18kb (18 432 b). <p /> Ethereum contract is absolutely open,
          feel free to check it before you pay anything –{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => copyToClipboard("0x123123123123123123")}
          >
            0x123123123123123123
          </span>
          .
          <p />
          Please do not hesitate to contact us for any additional information{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => copyToClipboard("support@ethereumwall.com")}
          >
            support@ethereumwall.com
          </span>
          <p /> Own your own advertising on the blockchain with Ethereum Wall!
        </div>
        <div
          className={"ew-Modal-buttons eu-centered"}
          style={{ width: "100%" }}
        >
          <EUButton {...{ content: "Close", onClick: close }} />
        </div>
      </div>
    </div>
  );
}
