import {useState, useRef, useEffect, useMemo} from "react";
import mmImage from "../../images/MetaMask.png";
import "./Header.css";
import About from "../About/About";
import ReactModal from "react-modal";
import UploadImageModal from "./../Upload/Upload";
import {
  blobToBase64,
  calculatePriseOfSeat,
  findPriceByImageId, PLACE_COUNT,
} from "../../functions/helpers";
import {
  AboutWrapper,
  AboutWrapperModal,
  ActionControlWrapper,
  HeaderActionWrapper,
  HeaderDescription,
  HeaderInfoBox,
  HeaderLogo,
  HeaderLogoName,
  HeaderWrapper,
  InfoWrapper,
  Logo,
  PriceBlock,
  SizeBlock,
  UploadActions,
  ButtonImage,
  ButtonContentWrapper,
  ConnectButtonWrap,
} from "./Header.style";
import EUButton from "../EUButton/EUButton";
import { isMobile } from "react-device-detect";

let currentSold = 0;
let currentAvailable = PLACE_COUNT;

function Header({
  sold,
  available,
  chosenBlock,
  hoveredBlock,
  updateChosen,
  toggleBuyPopup,
  isBuying,
  connectMetamask,
  payPostRequestToMetamask,
  pricesImage,
  myOwnImages,
  _isOwnBlock,
  _setIsOwnBlock,
  needConnect,
  isMetaMask,
  isLoading,
}) {
  const [about, toggleAbout] = useState(false);
  const [payActionAvailable, acceptPay] = useState(false);
  const [finishImage, setFinishImage] = useState(null);
  const [chosenID, setChosenID] = useState(null);
  const [imageDescriptionBefore, setDescriptionBefore] = useState(null);
  const [imageCaption, setCaption] = useState(null);
  const [imageURL, setURL] = useState(null);
  const refUpload = useRef(null);

  if(sold > currentSold){
    currentSold = sold;
    currentAvailable = available;
  }

  const customStyles = {
    content: {
      padding: "20px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "800px",
      maxHeight: "600px",
      minHeight: "200px",
      zIndex: "9999",
      position: "absolute",
      background:
        "linear-gradient(115.77deg, rgba(129, 169, 247, 0.8) 0%, #143F93 0.01%, #12B3AC 100%)",
      textAlign: "justify",
      alignItems: "center",
    },
  };

  useEffect(() => {
    if (chosenBlock?.id) {
      const elemObject = {
        id: chosenBlock?.getAttribute("id"),
      };
      setChosenID(Number(elemObject?.id.split("").splice(3).join("")));
    }
  }, [chosenBlock]);

  // Оплата места на борде
  const paySeatInBillboard = () => {
    const imageConfig = refUpload.current.getCurrentImageConfig();
    updateChosen(imageConfig);
    blobToBase64(finishImage)
      .then((base64Data) =>
        payPostRequestToMetamask(
          chosenID,
          base64Data,
          imageConfig?.caption,
          imageConfig?.imageURL,
          findPriceByImageId(pricesImage, chosenID)
        )
      )
      .catch((e) => console.log(e));
    toggleBuyPopup(false);
  };

  function closeBuyPopup() {
    refUpload.current.destroy();
    toggleBuyPopup(false);
    setFinishImage(null)
  }

  const isOwnBlock = (OwnsArray) =>
    chosenID && Boolean(OwnsArray.includes(chosenID));

  _setIsOwnBlock(isOwnBlock(myOwnImages));

  function closeAbout() {
    toggleAbout(false);
  }

  useEffect(() => {
    if (chosenBlock?.title) {
      setDescriptionBefore({
        title: chosenBlock.title,
        linkurl: chosenBlock.getAttribute('linkurl'),
      });
    }
  }, [chosenBlock]);

  useEffect(() => {
    if (chosenBlock?.title) {
      setCaption(
          chosenBlock.title,
      );
      setURL(
          chosenBlock.getAttribute("linkurl"),
      );
    }
    isImage()
  }, [chosenBlock]);


  const isImage = () => {
    if (chosenBlock && finishImage === null) {
      setFinishImage(chosenBlock.src);
    }
  };

  const isReadOnlyUpdateButton = useMemo(() =>
    !(
      (payActionAvailable && finishImage) ||
      imageCaption !== imageDescriptionBefore?.title ||
      imageURL !== imageDescriptionBefore?.linkurl
    ), [payActionAvailable, finishImage, imageCaption, imageDescriptionBefore, imageURL]
  );

  const isReadOnlyBuyButton = useMemo(() =>
    !(
      payActionAvailable &&
      finishImage &&
      imageCaption?.length > 0 &&
      imageURL?.length > 0
    ), [payActionAvailable, finishImage, imageCaption, imageURL]
  );

  return (
    <HeaderWrapper>
      <HeaderDescription>
        <HeaderLogo src="./images/logo.png" />
        <HeaderLogoName>
          <Logo>Own a  piece of Ethereum history</Logo>
        </HeaderLogoName>
      </HeaderDescription>

      {/* Центральная секция с набором основных инструментов */}
      <HeaderActionWrapper>
        <HeaderInfoBox>
          <span>1px = 0.0001 Eth</span>
        </HeaderInfoBox>
        <InfoWrapper>
          <span>Sold: {currentSold}</span>
          <span>Available: {currentAvailable}</span>
        </InfoWrapper>
        {chosenBlock || hoveredBlock ? (
          <ActionControlWrapper>
            <SizeBlock>
              <span className={"eu-Header-chosenStats "}>Block size</span>
              <span>
                {(hoveredBlock || chosenBlock).width +
                  " x " +
                  (hoveredBlock || chosenBlock).height}
              </span>
            </SizeBlock>

            <PriceBlock>
              <span className={"eu-Header-chosenStats "}>Price</span>
              <span>
                {calculatePriseOfSeat(
                  chosenBlock || hoveredBlock,
                  1,
                  _isOwnBlock
                )}
              </span>
            </PriceBlock>
          </ActionControlWrapper>
        ) : (
          <div className="pseudo-element">
            <div className="pseudo-element__description">
              Click the field below to see the price
            </div>
          </div>
        )}
      </HeaderActionWrapper>

      {!isMobile && needConnect === true && (
        <ConnectButtonWrap>
          <EUButton
            style={{ marginTop: 0 }}
            onClick={connectMetamask}
            content={
              <ButtonContentWrapper>
                <span>Connect</span> <ButtonImage src={mmImage} />
              </ButtonContentWrapper>
            }
          />
        </ConnectButtonWrap>
      )}

      <AboutWrapper>
        <div
          className="eu-about-link eu-cursor-pointer"
          onClick={() => toggleAbout(true)}
        >
          <span>About</span>
        </div>
      </AboutWrapper>
      {isMetaMask && !isLoading ? (
        <ReactModal
          style={customStyles}
          isOpen={isBuying}
          onRequestClose={closeBuyPopup}
        >
          <UploadImageModal
            myOwnImages={myOwnImages}
            finishImage={finishImage}
            setFinishImage={setFinishImage}
            activatePay={acceptPay}
            chosenBlock={chosenBlock}
            isOwnBlock={isOwnBlock}
            ref={refUpload}
            imageCaption={imageCaption}
            setCaption={setCaption}
            imageURL={imageURL}
            setURL={setURL}
          />
          <UploadActions>
            {_isOwnBlock ? (
              <EUButton
                content="Update"
                onClick={paySeatInBillboard}
                readOnly={isReadOnlyUpdateButton}
              />
            ) : (
              <EUButton
                content="Buy"
                onClick={paySeatInBillboard}
                readOnly={isReadOnlyBuyButton}
              />
            )}
            <EUButton content={"Close"} onClick={closeBuyPopup} />
          </UploadActions>
        </ReactModal>
      ) : null}
      <ReactModal
        style={customStyles}
        isOpen={about}
        onRequestClose={() => closeAbout()}
      >
        <About close={() => closeAbout()} />
      </ReactModal>
    </HeaderWrapper>
  );
}

export default Header;
